import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectPage } from '../../redux/actions';
import PageWr from '../../common/function/pageWr';
import './styles/contact.scss'
// import map from '../../_assets/other_image/contact_map/map.png';

class Contact extends Component {

  componentDidMount () {
    this.props.onPageMount("contact");
  }
  

  render() {
    return (
      <PageWr white={true}>
        <div className="contact">
          <div className="scroll">
            <div className="contact_us">
                Contact us <br />
            </div>
              <span className="span_wrap" />
              <div className="textDesc">
                <div className="imgMap">
                  <div className="titleLine" />
                  <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470.2451331852488!2d126.92438897792606!3d37.54517742189691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98ce89f8f2b1%3A0xc61f0f9dd1ceeff2!2zKOyjvCntjIzrqqjspog!5e0!3m2!1sko!2skr!4v1564449302069!5m2!1sko!2skr" allowFullScreen/>
                </div>
                <div className="explanation">
                  <div className="firstText">
                  서울시 마포구 토정로 121-1(상수동) 한강리버타워 1층, 3층 <br />
                  </div>
                  121-1, Tojeong-ro, Mapo-gu, Seoul, Republic of Korea 1F,3F <br />
                  <br />
                  Tel: +82 (02) 332 8148<br />
                  Fax: +82 (02) 332 8147<br />
                  Mail: crayon@famoz.co.kr<br />
                  Url: www.famoz.co.kr
                </div>
              </div>
            </div>
          </div>
      </PageWr>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch (selectPage(value))
  }
}

export default connect(undefined, mapDispatchToProps)(Contact)
