import { TimelineMax as Timeline, Power1 } from 'gsap';

// const getHomeTimeline = (node, delay) => {
//     const timeline = new Timeline({ paused: true });
//     const content = node.querySelector(".section:nth-child(1)");
//     // const texts = node.querySelectorAll(".black_back");

//     timeline
//     .from(node, 0.3, { display: 'none', autoAlpha: 0, delay, ease: Power1.easeIn })
//     .from(content, 0.15, { autoAlpha: 0, ease: Power1.easeInOut })
//     // .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

//   return timeline;
// }

const getDefaultTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    const texts = node.querySelectorAll(".wrapper_page *");

    timeline
    .from(node, 0, { display: 'none', autoAlpha: 0, delay })
    .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

  return timeline;
}

const works = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    const texts = node.querySelectorAll(".wrapper_page, .scroll > div");

    timeline
    .from(node, 0, { display: 'none', autoAlpha: 0, delay })
    .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

  return timeline;
}

export const play = (pathname, node, appears) => {
    if (pathname === "/") {
        return;
        // timeline = getHomeTimeline(node, delay);
    }
    const delay = appears ? 0 : 0.5;
    let timeline;
    
    if (pathname === "/works") {
        timeline = works(node, delay);
    } else {
        timeline = getDefaultTimeline(node, delay);
    }
        requestAnimationFrame(() => timeline.play());
    }

export const exit = (node) => {
    const timeline = new Timeline({ paused: true });

    timeline.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut });
    timeline.play();
}