export const LOCATION = "LOCATION";
export const SELECT_TAB = "SELECT_TAB";
export const SELECT_PAGE = "SELECT_PAGE";
export const OPEN_POPUP = "OPEN_POPUP";
export const OPEN_CONFIRM_POPUP = "OPEN_CONFIRM_POPUP";

export const location = (value) => {
    return {
        type: LOCATION,
        value: value
    }
}

export const selectTab = (value) => {
    return {
        type: SELECT_TAB,
        value: value
    }
}

export const selectPage = (value) => {
    return {
        type: SELECT_PAGE,
        value: value
    }
}

export const openPopup = (open, value) => {
     return {
         type: OPEN_POPUP,
         open: open,
         value: value
     }
}

export const openConfirmPopup = (value) => {
    return {
        type: OPEN_CONFIRM_POPUP,
        value: value
    }
} 