import React, { Component } from 'react';
import moment from "moment";

class Clock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            time: new Date().toLocaleString()
        };
    }

    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(), 1000
        );
        
        this.tick();
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    tick() {
        this.setState({
            time: moment(new Date()).format("HH:mm:ss")
            //time: moment(new Date()).format("HH:mm:ss:SS")
        }, () => {
            this.props.getTime(this.state.time);
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.time}
            </React.Fragment>
        );
    }
}

export default Clock;