import React, { Component } from 'react'
import '../../utils.scss';
import PropTypes from 'prop-types';

export default class PageWr extends Component {

    makeClassName = (bg, white) => {
        if (bg !== false) {
            // 색상값 들어왔을때
            return "black_back white";
        } else {
            if (!white) {
                return "black_back black";
            } else {
                return "black_back white"
            }
        }
    }

  render() {    
    const {children, image, white, padding, backgroundColor} = this.props;
    const { makeClassName } = this;
    return (
        <div className="wrapper_first" style={{ opacity: 1 }}>
        <div className="wrapper_" style={image !== false ? {backgroundImage: `url(${image})`} : {}}>
        </div>
            <div className={makeClassName(backgroundColor, white)} >
                {/* <div className="before" style={ !backgroundColor ? {} : { backgroundColor: `${backgroundColor} !important` }}> */}
                <div className="before" style={{ backgroundColor: backgroundColor }}>
                </div>
                <div className={ padding ? "wrapper_page padding" : "wrapper_page no_padding"}>
                    {children}
                </div>
            </div>
        </div>
    )
  }
}

PageWr.propTypes = {
    image: PropTypes.any,
    children: PropTypes.any,
    white: PropTypes.bool,
    padding: PropTypes.bool,
    backgroundColor: PropTypes.any
}

PageWr.defaultProps = {
    image: false,
    white: false,
    padding: true,
    backgroundColor: false
}