import { combineReducers } from 'redux';
import { LOCATION, SELECT_TAB, SELECT_PAGE, OPEN_POPUP, OPEN_CONFIRM_POPUP } from './../actions/index';

const location = (state = {value: "#firstPage"}, action) => {
    switch (action.type) {
        case LOCATION:
            return {value: action.value } ;
        default: 
            return state;
    }
}

const selectTabs = (state = {value: "ALL"}, action) => {
    switch (action.type) {
        case SELECT_TAB:
            return {value: action.value } ;
        default: 
            return state;
    }
}

const selectPage = (state = {value: "home"}, action) => {
    switch (action.type) {
        case SELECT_PAGE:
            return { value: action.value };
        default:
            return state;
    }
}

const openPopup = (state = {open: false, value: false}, action) => {
    switch (action.type) {
        case OPEN_POPUP:
            return { open: action.open, value: action.value };
        default:
            return state;
    }
}

const openConfirmPopup = (state = { value: {open: true, title: "title!!!", subtitle: "sub title !!!", description: "descripion !!"}}, action) => {
    switch (action.type) {
        case OPEN_CONFIRM_POPUP: 
            return {open: action.open, title: action.title ? action.title : "", subtitle: action.subtitle ? action.subtitle : "", description:  action.description ? action.description : ""};
        default: 
            return state;
    }
}

const Homepage = combineReducers({
    location,
    selectTabs,
    selectPage,
    openPopup,
    openConfirmPopup
});

export default Homepage;