import React, { Component } from 'react'
import './styles/section.scss';
import Render from './render';
import PropTypes from 'prop-types';

export default class section extends Component {


  render() {
    const { white, image, children, classN, animation } = this.props;
    return (
      <div className={"section " + classN}>
        <Render children={children} image={image} white={white} animation={animation} />
      </div>
    )
  }
}

Render.propTypes = {
  image: PropTypes.any,
  children: PropTypes.any,
  white: PropTypes.bool
}

Render.defaultProps = {
  white: false,
  animation: false
}
