// Polyfill 설정은 제일 첫줄에서 이뤄져야 한다.
// ie11이면 끝난다.
// import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import counterApp from './redux/reducers';

import i18n from './i18n/i18n';
import { I18nextProvider } from 'react-i18next';

// HOC 가동
// https://velopert.com/3537
import withSplitting from './withSplitting';

export const HOCMainPage = withSplitting(() => import('./fullpage/fullpageWr'));
export const HOCWorks = withSplitting(() => import('./page/works/works'));
export const HOCSolution = withSplitting(() => import('./page/solution/solution'));
export const HOCTeams = withSplitting(() => import('./page/teams/teams'));
export const HOCContact = withSplitting(() => import('./page/contanct/contact'));

const store = createStore(counterApp);

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>,
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
