import React, { Component } from 'react'
import NET from '../../../common/function/net';
import StackGrid from "react-stack-grid";
import i18n from '../../../i18n/i18n';


export default class CardInner extends Component {

    renderGridImage = (datas) => {
        let result = [];
        for (let i = 0; i < datas.length; i++) {
            const dt = datas[i];
            if (!dt) {
                continue;
            }

            result.push(
                <StackGrid monitorImagesLoaded={true} style={{ height: "100%" }} key={i} columnWidth={(dt.width === null || dt.width === "") ? "100%" : dt.width}>
                    {dt.image.map((value, index) => (
                        <img key={index} src={NET._IP() + "resources/" + value} alt="이미지" />
                    ))}
                </StackGrid>
            )
        };
        return result;
    }

    render() {
        const { title, subtitle, desc, contents } = this.props.data;

        return (
            <div className="detailInner">
                <div className="title_wrapper">
                    <p className="title_left">
                        {title[i18n.language]}
                    </p>
                    <p className="title_right">
                        {subtitle[i18n.language]}
                    </p>
                </div>

                <p className="description">
                    <span dangerouslySetInnerHTML={{ __html: desc[i18n.language] }}></span>
                </p>

                <div className="imageWrapper">
                    {/* <FbImageLibrary hideOverlay={true} images={result} /> */}
                    {/* <Gallery 
                    images={result}  
                    enableLightbox={false}
                    enableImageSelection={false}
                    /> */}
                    {/* 
                    <Grid container spacing={2}>
                        { result.map((value, i) => (
                            <Grid key={i} xs={12}>
                                <img src={value.src} style={{width: "100%"}} alt={"contents img"} />
                            </Grid>
                        )) }
                    </Grid> */}
                    {this.renderGridImage(contents)}
                </div>

            </div>
        )
    }
}
