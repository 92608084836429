import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '../reactTransition';

import MainPage from '../fullpage/fullpageWr';
import Works from '../page/works/works';
import Solution from '../page/solution/solution';
import Teams from '../page/teams/teams';
import Contact from '../page/contanct/contact';
import MenuArea from './../menuArea/menuArea';
import { connect } from 'react-redux';
import SolutionInfo from '../page/solution/solutionInfo';
// import SuccessPopup from '../common/render/successPopup';

class router extends Component {

  state = {
    node: null,
  }

  renderRoute = (path) => {
    if (path === "home") {
      return (
        <React.Fragment>
          <Route exact path="/"
            render={(props) => (
              <MainPage {...props} />
            )} />

          <Route path="/works"
            render={(props) => (
              <Works {...props} />
            )} />

          <Route path="/solution"
            exact
            render={(props) => (
              <Solution {...props} />
            )} />

          <Route path="/solution/info"
            render={(props) => (
              <SolutionInfo {...props} />
            )} />

          <Route path="/teams"
            render={(props) => (
              <Teams {...props} />
            )} />

          <Route path="/contact"
            render={(props) => (
              <Contact {...props} />
            )} />
        </React.Fragment>
      )
    } else {
      return (
        <Route render={({ location }) => {
          const { pathname, key } = location;

          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                transitionLeave={false}
                onEnter={(node, appears) => {
                  play(pathname, node, appears);
                }}
                exit={false}
                onExit={(node, appears) => exit(node, appears)}
                timeout={{ enter: 750, exit: 150 }}>

                <Switch location={location}>
                  <Route exact path="/"
                    render={(props) => (
                      <MainPage {...props} />
                    )} />

                  <Route path="/works"
                    render={(props) => (
                      <Works {...props} />
                    )} />

                  <Route path="/teams"
                    render={(props) => (
                      <Teams {...props} />
                    )} />

                  <Route path="/solution"
                    exact
                    render={(props) => (
                      <Solution {...props} />
                    )} />

                  <Route path="/solution/info"
                    render={(props) => (
                      <SolutionInfo {...props} />
                    )} />

                  {/* <Route path="/solution/info"
                      render={(props) => (
                        <Solutioninfo {...props} />
                      )} />

                      <Route path="/solution/usecase"
                      render={(props) => (
                        <Solutionusecase {...props} />
                      )} />

                      <Route path="/solution/function"
                        render={(props) => (
                        <Solutionfunction {...props} />
                        )} /> */}

                  <Route path="/contact"
                    render={(props) => (
                      <Contact {...props} />
                    )} />
                </Switch>

              </Transition>
            </TransitionGroup>
          )

        }} />
      )
    }
  }

  render() {
    const {path} = this.props;
    return (
      <Router>
        <Grid container>
          {this.renderRoute(path)}
          <MenuArea />
        </Grid>
      </Router>
    )
  }
}

const props = (state) => {
  return {
      path: state.selectPage.value,
      openPopup: state.openConfirmPopup.value
  }
}

export default connect(props, undefined)(router)