import React, { Component } from 'react'
import { connect } from 'react-redux'
import TeamsListWr from './fucntion/teamListWr';
import PageWr from '../../common/function/pageWr';
import './styles/teams.scss';
import { selectPage } from '../../redux/actions';
import NET from './../../common/function/net';

class Teams extends Component {

  _isMounted = false;

  state = {
    team: [],
    render: false
  }

  constructor() {
    super();
    this.net = new NET();
  }

  componentDidMount () {
    this._isMounted = true;
    this.props.onPageMount("teams");
    this.getTeamsData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      render: false
    })
  }

  getTeamsData = () => {
    this.net.get("/api/homepage?menu=team").then(res => {
      if(this._isMounted === true) {
        this.setState({ team: res.data, render: true })
      }
    })
    .catch(err => {
    })
  }

  render() {
    const { team } = this.state;

    if(this.state.render) {
      return (
        <PageWr image={team.mainImg ? `${ NET._IP() + "resources/" + team.mainImg[0] }` : ""}>
          <div className="teams">
            <div className="title_big">
              OUR TEAM
            </div>
            <TeamsListWr team={team}/>
          </div>
        </PageWr>
      )
    }
    else {
      return (<div/>);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch (selectPage(value))
  }
}

export default connect(undefined, mapDispatchToProps)(Teams)
