import React, { Component } from 'react'
import Router from './router/router';
import i18n from "./i18n/i18n";


export default class App extends Component {

  state = {
    reRender: false,
  }

  componentDidMount = () => {
    i18n.changeLanguage("ko");
    i18n.on('languageChanged', (lng) => {
      this.setState({ reRender: !this.state.reRender });
  });
  }

  render() {
    return (
      <React.Fragment>
        <Router render={this.state.reRender} />
      </React.Fragment>
    )
  }
}
