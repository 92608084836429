import React, { Component } from 'react'
import Section from '../../fullpage/section';
import famozTypo from '../../_assets/other_image/logo/famoztypo.png';
import { NavLink } from "react-router-dom";
import './styles/fifthPage.scss';
import NET from '../../common/function/net';
import ContactPopup from './function/contactPopup';
import { connect } from 'react-redux';
import { openConfirmPopup } from '../../redux/actions';
import SuccessPopup from './../../common/render/successPopup';
import FullpageEvent from './../../fullpage/fullpageEvent';
import i18n from '../../i18n/i18n';

class FifthPage extends Component {

  state = {
    open: false,
    modalOpen: false,
  }

  constructor() {
    super();
    this.net = new NET();
  }

  setStateHandler = (name, value) => {
    if (name === "open") {
      if (value === true) {
        // disableScrol l.on();
        FullpageEvent.root().emit("openPopup");
      } else {
        // disableScroll.off();
        FullpageEvent.root().emit("closePopup");
      }
    }
    this.setState({ [name]: value });
  }

  // 메일 콘택트  
  postMailContact = (data) => {
    this.net.post(`/api/mail/post`, {
      query : {
        category: data.category,   // 카테고리(옵션)
        company: data.company,  // 회사
        name: data.name,    // 이름
        phone: data.phone,   // 번호
        email: data.email,      // 이메일(옵션)
        desc: data.desc      // 설명
      }
    })
    .then(res => {
      this.setState({ modalOpen: true })
    })
    .catch(err => {
    })
  }

  render() {
    const home = this.props.home;
    const {open, modalOpen} = this.state; 
    const {setStateHandler, postMailContact} = this;

    return (
        <Section image={ (home === undefined) ? "" : NET._IP() + "resources/" + home.mainImg } classN={"five"}>
          <div className="fifth_">
            <img src={famozTypo} className="famoztypo" alt="famoz lion"/>
            <div className="one">
              <h3>FAMOZ<br/>Creative Partner</h3>
            </div>
            <div className="two">
            </div>
            <div className="three">
              <ContactPopup setStateHandlerProps={setStateHandler} data={home} open={open} postMailContact={postMailContact} />
              <div className="threeInner">
                <div className="three_inner_wrapper">
                  <h3>Work with us.</h3>
                  <h4>Client Engagement</h4>
                  <div onClick={(e) => setStateHandler("open", true)} className="emailArea">
                    <p>{ (home === undefined) ? "" : home.works }</p>
                    <div className="mailicon"><p>✉</p></div>
                  </div>
                </div>
              </div>
              <div className="threeInner">
                <h3>Careeres.</h3>
                <h4>Recruiting</h4>
                <div className="emailArea">
                  <p>{ (home === undefined) ? "" : home.career }</p>
                  <div className="mailicon"><p>✉</p></div>
                </div>
              </div>
              <div className="threeInner">
                <h3>More Portfolio.</h3>
                <h4>
                  <NavLink to={"/works"}>
                    Sharing our experiences >
                  </NavLink>
                </h4>
              </div>
            </div>
            <SuccessPopup title={i18n.t("home.thanks1")/*감사합니다*/} open={modalOpen} subtitle={i18n.t("home.thanks2")/*성공적으로 작업을 마쳤습니다*/} description={i18n.t("home.thanks3"/*최대한 빠르게 답변 드리겠습니다.*/)} close={(e) => setStateHandler("modalOpen", false)}/>
          </div>
        </Section>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (open, value) => dispatch (openConfirmPopup(open, value))
    }
}

export default connect (undefined, mapDispatchToProps) (FifthPage);