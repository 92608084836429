import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import lang_ko from "./ko.json";
import lang_en from "./en.json";
import lang_cn from "./cn.json";
import lang_jp from "./jp.json"

i18n.use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        interpolation: { escapeValue: false },
        ns: ["translations"],
        defaultNS: "translations",
        debug: false,
        lng: 'ko',              // 기본 사용 언어.
        fallbackLng: "ko",      // 사용자 언어의 번역을 사용할 수 없는 경우 사용할 언어.
        resources: {
            ko: { translations: lang_ko },
            en: { translations: lang_en },
            cn: { translations: lang_cn },
            jp: { translations: lang_jp },
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;