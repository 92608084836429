import React, { Component } from 'react'
import f_icon from '../../_assets/other_image/footer_icon/facebook_icon.png';
import in_icon from '../../_assets/other_image/footer_icon/in_icon.png';
import v_icon from '../../_assets/other_image/footer_icon/vimeo_icon.png';
import y_icon from '../../_assets/other_image/footer_icon/youtube_icon.png';

export default class Footer extends Component {
  render() {
    return (
        <div className="footer">
          <p>FAMOZ CREATIVE PARTNER</p>
          <p><img src={f_icon} alt="facebook logo" /></p>
          <p><img src={in_icon} alt="in logo" /></p>
          <p><img src={v_icon} alt="vimeo logo" /></p>
          <p><img src={y_icon} alt="youtube logo" /></p>
        </div>
    )
  }
}
