import React, { Component } from 'react';
import './styles/render.scss';
import PropTypes from 'prop-types';

export default class Render extends Component {

    state = {
        white: this.props.white,
        animation: this.props.animation
    }
    render() {

        const { children, image } = this.props;
        const { white } = this.state;
        // const {animation} = this.state;
        return (
            <React.Fragment>
                {/* <div className="image_wrap"  style={{backgroundImage: `url(${image})`}}> */}
                {/* <div className={animation === true ? "animation" : "notAnimation"} /> */}
                <img className="image_wrap" src={image} alt={""} />
                <div className={white === true ? "black_back white" : "black_back black"}>
                    <div className="before"></div>
                    <div className="contents">
                        <div className="inner_contetns">
                            {children}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

Render.propTypes = {
    image: PropTypes.any,
    children: PropTypes.any
}

Render.defaultProps = {
    white: false,
    animation: false
}