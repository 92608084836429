import React, { Component } from 'react'
import Section from '../../fullpage/section';
import './styles/thirdPage.scss';
import NET from '../../common/function/net';
import i18n from '../../i18n/i18n';

export default class thirdPage extends Component {

  constructor() {
    super();
    this.net = new NET();
  }

  renderSection = (d) => {
    if (!d) {
      return;
    }

    const datas = d.contents;

    let result = [];

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];
      result.push(
        <div key={i} className="sec">
          <div className="inner_wrapper">
            <p className="indexing">{`0${i + 1}`}</p>
            <p className="show_title">
              {dt.title[i18n.language]}
            </p>
            <p className="hide_desc">
              {dt.description[i18n.language]}
            </p>
          </div>
        </div>
      )
    }

    return result;
  }


  render() {
    const home = this.props.home;

    return (
      <Section className="imgAnimation" image={(home === undefined) ? "" : NET._IP() + "resources/" + home.mainImg} classN={"three"}>
        <div className="third_">
          <div className="over_section_third">
            {this.renderSection(home)}
          </div>
        </div>
      </Section>
    )
  }
}
