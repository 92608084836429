import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './styles/menuArea.scss';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';

class menuArea extends Component {

  state = {
    selectedOption: null,
    menu: [
      "home", "works", "teams", "contact"
    ],
    checked: false,
    isMobile: false
  }

  componentDidMount = () => {
    if(window.innerWidth <= 414) {
      this.setState({isMobile: true});
    }
  }

  componentDidUpdate = (prevPros) => {
    if (prevPros.path !== this.props.path) {
      this.setStateInput();
    }
  }

  renderMenu = (datas) => {
    let result = [];
    const {path} = this.props;

    for (let i = 0; i < datas.length; i ++) {
      const dt = datas[i];
      const name = this.initCap(dt);
      result.push(   
        <NavLink key={i} activeClassName="active"
         className={path === dt ? "list select" : "list noSelect"} 
         exact to={dt === "home" ? "/" : "/" + dt}>
          <li>{name}</li>
        </NavLink>
      )
    }
    return result;
  }

  initCap = (str) => {
    const stri = str.substring(0, 1).toUpperCase() + str.substring(1, str.length).toLowerCase();
    return stri;
   }

   setStateInput = (e) => {
     if (!e) {
       this.setState({ checked: false })
       return;
     }
     this.setState({ checked: e.target.checked })
   }

   
  handleChange = (selectedOption) => {
    this.setState({ });
    i18n.changeLanguage(selectedOption);
  }


  renderToLanguageSelect = () => {
    if(this.state.isMobile) {
      if(this.state.checked) {
        return (                
          <div className="changeLangWrapper">
              <div className={i18n.language === "ko" ? "changeLang select" : "changeLang"} onClick = {(e) => { this.handleChange("ko") }}>KR</div>
              <div className={i18n.language === "en" ? "changeLang en select" : "changeLang en"} onClick = {(e) => { this.handleChange("en") }}>EN</div>
          </div>
        );
      }
      else {
        return (<div/>);
      }

    }
    else {
      return (                
        <div className="changeLangWrapper">
            <div className={i18n.language === "ko" ? "changeLang select" : "changeLang"} onClick = {(e) => { this.handleChange("ko") }}>KR</div>
            <div className={i18n.language === "en" ? "changeLang en select" : "changeLang en"} onClick = {(e) => { this.handleChange("en") }}>EN</div>
        </div>
      );
    }
  }


  render() {
    const { menu, checked } = this.state;
    return (
        <div className="menuAreaWrapper">
          <nav role="navigation">
              <div id="menuToggle">
                <input type="checkbox" onChange={(e) => this.setStateInput(e)} checked={checked}/>
                {this.renderToLanguageSelect()}
                <span></span>
                <span></span>
                <span></span>

                <ul id="menu">
                {/* <div className="changeLangWrapperMenu">
                    <div className={i18n.language === "ko" ? "changeLangMemnu ko select" : "changeLangMemnu ko"} onClick = {(e) => { this.handleChange("ko") }}>KR</div>
                    <div className={i18n.language === "en" ? "changeLangMemnu en select" : "changeLangMemnu en"} onClick = {(e) => { this.handleChange("en") }}>EN</div>
                </div> */}
                {this.renderMenu(menu)}

                    <div className="infoWrapper"> 
                      <p id="adress">{i18n.t("common.address")}<br/>Tel 02.332.8148 / Fax 02.332.8147</p>
                      <p className="link">View Map</p>
                      <p>Work with us.</p>
                      <p className="link">contact@famoz.co.kr</p>
                      <p>Recruiting</p>
                      <p className="link">recruit@famoz.co.kr</p>
                    </div>
                </ul>
              </div>
          </nav>
        </div>
    )
  }
}

const props = (state) => {
  return {
    path: state.selectPage.value,
  }
}

export default connect(props, undefined)(menuArea)