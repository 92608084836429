import React, { Component } from 'react';
import clipboardCopy from "clipboard-copy";
import i18n from '../../../i18n/i18n';

export default class TeamList extends Component {

  state = {
    showTooltip: [],
  }

  componentDidMount = () => {
    let toolTipArray = [];
    for (let i = 0; i < this.props.data.length; i++) {
      toolTipArray.push(false);
    }
    this.setState({ showTooltip: toolTipArray });
  }


  renderList = (datas) => {
    let results = [];

    datas = datas.sort((a, b) => {
      const ao = Number(b.order);
      const bo = Number(a.order);
      return bo < ao ? -1 : bo > ao ? 1 : 0;
    });

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];

      results.push(
        <li key={dt.name + i}
          onClick={(e) => {
            this.setState(prevState => {
              const newItems = [...prevState.showTooltip];
              newItems[i] = true;
              return { showTooltip: newItems };
            }, () => {
              clipboardCopy(dt.email);

              // n초 뒤 원복
              setTimeout(() => {
                this.setState(prevState => {
                  const newItems = [...prevState.showTooltip];
                  newItems[i] = false;
                  return { showTooltip: newItems };
                });
              }, 1500);
            });
          }}>
          <p className="name">{dt.name[i18n.language]}</p>
          <p className="role">{dt.role}</p>
          { this.state.showTooltip[i] === true ? 
            <p className="hoverEmail clip">
              Copied to clipboard!
            </p>
            :
            <p className="hoverEmail">
              {dt.email} <span>COPY</span>
            </p>
          }
        </li>
      );
    }
    return results;
  }


  render() {
    const { name, data } = this.props;

    return (
      <div className="list_all">
        <p className="list_name">
          {name}
        </p>
        <div className="list_">
          <ul className="list_scroll">
            {this.renderList(data)}
          </ul>
        </div>
      </div>
    )
  }
}
