import React, { Component } from 'react'
import { connect } from 'react-redux'
import PopupWr from '../../../common/render/popupWr';
import CardInner from './cardInner';
import '../styles/worksPopup.scss';
import NET from '../../../common/function/net';
import i18n from '../../../i18n/i18n';
import { openPopup } from './../../../redux/actions/index';

class WorksPopup extends Component {

    renderProjectDetail = (datas) => {
        if (!datas) return;
        let result = [];

        for (let i = 0; i < datas.length; i++) {
            const dt = datas[i];
            result.push(<CardInner key={i} data={dt} />)
        }
        return result;
    }

    handleOnClickContactUs = async () => {
        await this.props.openPopup(false, null);
        await this.props.setStateHandler('open', true);
    }

    render() {
        if (this.props.data === undefined || this.props.data.length <= 0) {
            return <PopupWr></PopupWr>
        };

        // 원래 서버에서 가져왔을때 로직
        const { mainImg, overview, video, detail } = this.props.data;
        const { handleOnClickContactUs } = this;

        return (
            <PopupWr>
                <div className="mainImage">
                    <img src={mainImg[0] ? NET._IP() + "resources/" + mainImg[0] : ""} alt="메인 이미지" />
                </div>

                <div className="overView">
                    <p className="title">
                        OVERVIEW
                    </p>
                    <p className="border"></p>
                </div>
                <div className="description">
                    <div dangerouslySetInnerHTML={{ __html: overview[i18n.language] }} />
                    {/* { danger  overview } */}
                </div>

                <div className="mainVideo">
                    <iframe id="iMovie" title="mainVideo" name="movieFrame" width={"100%"} height={"100%"} src={video} frameBorder="0" allowFullScreen="" mozallowfullscreen="" webkitallowfullscreen="" />
                </div>

                <div className="overView">
                    <p className="title">
                        PROJECT Detail
                    </p>
                    <p className="border"></p>
                </div>

                {this.renderProjectDetail(detail)}
                <div className="contact_works" onClick={(e) => handleOnClickContactUs()}>Contact us</div>
            </PopupWr>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (open, value) => dispatch (openPopup(open, value))
    }
}

export default connect(undefined, mapDispatchToProps)(WorksPopup);
