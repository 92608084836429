import React, { Component } from 'react'
import Section from '../../fullpage/section';
// section을 import 해와서 그 안에서만 작업 해주세요.
import './styles/firstPage.scss';
import Clock from '../../common/function/clock';
import { NavLink } from "react-router-dom";
import NET from '../../common/function/net';
import { Icon } from '@material-ui/core';

export default class FirstPage extends Component {
  _isMounted = false;
  state = {
    time: "00:00:00",
  }


  renderTime = (time) => {

    // let time = "18:30:00";
    let opens = false;

    const hour = Number(String(time).slice(0, 2));
    const min = Number(String(time).slice(3, 5));
    const sec = Number(String(time).slice(6, 8));
    if ((hour >= 9) && (hour <= 18)) {
      if (hour === 18) {
        if (min <= 29 && sec < 60) {
          opens = true;
        } else {
          opens = false;
        }
      } else {
        if (hour === 9 && min >= 30) {
          opens = true;
        } else {
          opens = true;
        }
      }
    } else {
      opens = false;
    }

    if (opens) {
      return (
        <React.Fragment>
          ON AIR <span>09:30 - 18:30</span><br/>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          OFF AIR<br/>
        </React.Fragment>
      )
    }
  }
    
  changeTime = (time) => {
    this.setState({ time: time })
  }


  render() {
    // const {state, fullpageApi} = this.props;
    // 전달된 props는 기본적으로 state(fullpage의 현재 상황)와 fullpageApi(on 으로 시작하는 메서드 등) 입니다.
    const home = this.props.home;
    return (
      // 꼭 이 안에서 작업해주세요
        <Section className="imgAnimation" image = { (home === undefined) ? "" : NET._IP() + "resources/" + home.mainImg } classN={"one"}>
          <div className="first_">
            <p className="md_text">
              <span className="bold_text">{ (home === undefined) ? "" : home.title1 }</span><br/>
              { (home === undefined) ? "" : home.title2 }<br/>
              { (home === undefined) ? "" : home.title3 }<br/>
            </p>
            <div className="portfolio">
              <NavLink to="/works" style= {{ textDecoration: 'none', color: '#f26631'}}>
                <div className="contents">PORTFOLIO
                <Icon>arrow_right</Icon>
                  <p className="hoverContents"></p>
                  {/* <p className="hoverContents"><Icon>arrow_forward_ios</Icon></p>
                  <p className="hoverContents"><Icon>keyboard_arrow_right</Icon></p> */}
                  {/* <p className="hoverContents"><Icon>arrow_right_alt</Icon></p> */}
                </div>

              </NavLink>
            </div>
            <p className="time_text">
              {this.renderTime(this.state.time)}
              <span className="time_num">
                <Clock getTime={this.changeTime}/>
              </span>
            </p>
          </div>
        </Section>
    )
  }
}

