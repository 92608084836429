import React, { Component } from 'react'
import Section from '../../fullpage/section';
import './styles/secondPage.scss';
import NET from '../../common/function/net';
import i18n from '../../i18n/i18n';

export default class secondPage extends Component {
  constructor() {
    super();
    this.net = new NET();
  }
  
  render() {
    const home = this.props.home;
    if (!home) {
      return (<div />);
    } else {
      return (
        <Section image={NET._IP() + "resources/" + home.mainImg} classN={"two"}>
          <div className="second_">
            <div className="brand_name_wr">
              <p className="par one">FAMOZ<br />World Best Media Contents Solution Partner</p>
              <div className="par two">
              </div>
            </div>
            <div className="text_wr">
              <p dangerouslySetInnerHTML={{ __html: home.title[i18n.language] }} />
            </div>
          </div>
        </Section>
      )
    }
  }
}
