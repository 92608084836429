import React, { Component } from 'react';
import FirstPage from '../page/home/firstPage';
import SecondPage from '../page/home/secondPage';
import ThirdPage from '../page/home/thirdPage';
import FourthPage from '../page/home/fourthPage';
import FifthPage from '../page/home/fifthPage';

export default class sections extends Component {

  componentWillMount = () => {
  }

  render() {
    const { fullpageApi, state, home } = this.props;

    return (
    <React.Fragment>

      {/* 페이지로 만든 컴포넌트는 여기서 FirstPage처럼 렌더해주시고 props로는 
      // fullpageApi={fullpageApi} state={state}
      // 이렇게만 전달해주세요
      */}

      <FirstPage fullpageApi={fullpageApi} home={home[0]} state={state} />
      <SecondPage fullpageApi={fullpageApi} home={home[1]} state={state} />
      <ThirdPage fullpageApi={fullpageApi} home={home[2]} state={state} />
      <FourthPage fullpageApi={fullpageApi} home={home[3]} state={state} />
      <FifthPage fullpageApi={fullpageApi} home={home[4]} state={state} />


    </React.Fragment>
    )           
  }
}
