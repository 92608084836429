import React, { Component } from 'react'
import { Fade, Icon } from '@material-ui/core';
import './styles/successPopup.scss';
import { connect } from 'react-redux';
import { openConfirmPopup } from '../../redux/actions';

class SuccessPopup extends Component {
    render() {
        const {title, open, subtitle, description} = this.props;
        return (
            <Fade in={open}
                mountOnEnter 
                timeout={{
                appear: 400,
                enter: 400,
                exit: 400,
                }} unmountOnExit>
                <div className="success_popup_wrapper">
                    <div className="success_popup">
                        <div className="header">
                            <p><Icon>check</Icon></p>
                        </div>
                        <div className="contents_wrapper">
                            <p className="title">
                                {title}
                            </p>
                            <p className="subtitle">
                                {subtitle}
                            </p>
                            <p className="desc">
                                {description}
                            </p>
                        </div>
                        <div className="close_button" onClick={(e) => this.props.close("modalOpen", false)}>
                            OK
                        </div>
                    </div>
                </div>
            </Fade>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (open, value) => dispatch (openConfirmPopup(open, value))
    }
}

export default connect (undefined, mapDispatchToProps) (SuccessPopup);