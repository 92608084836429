import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageWr from '../../common/function/pageWr';
import Icon from '../../_assets/other_image/logo/famoz.png';
import CardWr from './function/cardWr';
import './styles/works.scss';
import MenuTab from './function/menuTab';
import Footer from '../../common/render/footer';
import { NavLink } from "react-router-dom";
import { selectPage, openPopup } from '../../redux/actions';
import WorksPopup from './function/worksPopup';
import NET from './../../common/function/net';
import SuccessPopup from './../../common/render/successPopup';
import ContactPopup from './../home/function/contactPopup';
import i18n from '../../i18n/i18n';

class Works extends Component {

  _isMounted = false;

  state = {
    work: [],
    selectCardData: [],
    renderer: false,
    modalOpen: false,
    open: false,
    home: null,
    selectType: "",
    selectMenuType: "",
  }

  constructor() {
    super();
    this.net = new NET();
  }

  componentDidMount() {
    this._isMounted = true;

    this.props.onPageMount("works");
    this.getWorksData();
    this.getHomeData();
  }


  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      renderer: false
    });
  }

  getWorksData = () => {
    // 대충 처음에 서버에서 데이터 불러오는 메서드
    this.net.get("/api/homepage/?menu=work")
      .then(res => {
        if (this._isMounted === true) {
          this.setState({ work: res.data });
        }
      })
      .catch(err => {
      })
  }

  getSelectData = (title, name, all) => {
    this.net.get(`/api/homepage?menu=work&workID=${title}`)
      .then(res => {
        this.setState({ selectCardData: res.data[0], selectType: name, selectMenuType: all.type }, () => {
          this.props.openPopup(true, title);
        });
      })
      .catch(err => {
      })
    // 대충 서버에서 이름으로 특정 데이터만 가져오는 메서드
  }

  getDataOfType = (types, name, all) => {
    let type = types;

    switch (types) {
      case "ALL":
        type = false;
        break
      case "INTERACTIVE MEDIA":
        type = "Interactive Media";
        break;
      case "DIGITAL SIGNAGE":
        type = "Digital Signage";
        break;
      case "EXHIBITION":
        type = "Exhibition";
        break;
      case "FILM":
        type = "Film";
        break;
      case "LAB":
        type = "Lab";
        break;
      default:
        break;
    }

    let query = `/api/homepage?menu=work&type=${type}`;
    if (!type) {
      query = `/api/homepage?menu=work`
    }


    this.net.get(query)
      .then(res => {
        this.setState({ work: res.data });
      })
      .catch(err => {
      })
  }

  getHomeData = () => {
    this.net.get("api/homepage/?menu=home").then(res => {
      this.setState({ home: res.data, render: true }, () => {
        this.setState({ home: res.data[4], renderer: true })
      });
    }).catch(err => {
    });
  }

  setStateHandler = (name, value) => {
    this.setState({ [name]: value })
  }  
  
  // 메일 콘택트  
  postMailContact = (data) => {
    this.net.post(`/api/mail/post`, {
      query : {
        category: data.category,   // 카테고리(옵션)
        company: data.company,  // 회사
        name: data.name,    // 이름
        phone: data.phone,   // 번호
        email: data.email,      // 이메일(옵션)
        desc: data.desc,      // 설명
        type: this.state.selectMenuType,
        title: this.state.selectType
      }
    })
    .then(res => {
        this.setState({ modalOpen: true, selectType: "", selectMenuType: "" }, () => {
      })
    })
    .catch(err => {
    })
  }


  render() {

    const { work, selectCardData, modalOpen, open, home, selectType } = this.state;
    const { getSelectData, getDataOfType, setStateHandler, postMailContact } = this;

    if (this.state.renderer) {
      return (
        <React.Fragment>
          <PageWr image={false} white={true}>
            <div className="works_">
              <div className="logo_title">
                <div className="logo">
                  <NavLink to="/">
                    <img src={Icon} alt={"FAMOZ Logo"} />
                  </NavLink>
                </div>
                <div className="title">
                  <p className="bold">FAMOZ</p>
                  <p>our work design</p>
                </div>
              </div>

              <div className="card_all_wrapper">
                <MenuTab getDataOfType={getDataOfType} />
                <CardWr getSelectData={getSelectData} data={work} />
              </div>

              <Footer />
              
            </div>
            <WorksPopup setStateHandler={setStateHandler} data={selectCardData} />
            <SuccessPopup title={i18n.t("home.thanks1")/*감사합니다*/} open={modalOpen} subtitle={i18n.t("home.thanks2")/*성공적으로 작업을 마쳤습니다*/} description={i18n.t("home.thanks3"/*최대한 빠르게 답변 드리겠습니다.*/)} close={(e) => setStateHandler("modalOpen", false)}/>

          </PageWr>
          <ContactPopup contents={selectType} wrapper={"works_popup"} setStateHandlerProps={setStateHandler} data={home} open={open} postMailContact={postMailContact} />
        </React.Fragment>
      );
    }
    else {
      return (<div />);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch(selectPage(value)),
    openPopup: (open, value) => dispatch(openPopup(open, value))
  }
}

export default connect(undefined, mapDispatchToProps)(Works)
