import _axios from "axios";

// const IP = "http://61.73.79.136:7777/";    // 개발용
// const IP = "https://famoz.co.kr/";            // 최종
const IP = window.location.origin + "/";

export default class NET {

    static _IP = () => {
        return IP;
    }
    static callback;


    token = () => {
        const token = sessionStorage.getItem("famoz_user");
        return token;
    }

    get = (url, option) => {
        return new Promise((resolve, reject) => {
            try {
                if (!url) {
                    throw new Error("Empty url");
                }
                const token = this.token();
                const axios = _axios.create({
                    baseURL: IP,
                    timeout: 10000,
                    headers: {
                        'famoz-horizon-web-frontend': 'SUPER_CALL',
                        "x-access-token": token
                    },
                    withCredentials: true
                });
                axios.get(url, option).then(data => {
                    if (data.data._COM !== undefined && data.data._COM.code === 4100) {
                        if (NET.callback) {
                            NET.callback();
                        }
                    }
                    resolve(data.data);
                }).catch(e => {
                    reject(e);
                });
            } catch (e) {
                reject(e);
            }
        });
    }

    put = (url, data, option) => {
        return new Promise((resolve, reject) => {
            try {
                if (!url) {
                    throw new Error("Empty url");
                }
                const token = this.token();
                const axios = _axios.create({
                    baseURL: IP,
                    headers: {
                        'famoz-horizon-web-frontend': 'SUPER_CALL',
                        "x-access-token": token
                    },
                    withCredentials: true
                });
                axios.put(url, data, option).then(data => {
                    if (data.data._COM !== undefined && data.data._COM.code === 4100) {
                        if (NET.callback) {
                            NET.callback();
                        }
                    }
                    resolve(data.data);
                }).catch(e => {
                    reject(e);
                })
            } catch (e) {
                reject(e);
            }
        });
    }


    post = (url, body, option) => {
        return new Promise((resolve, reject) => {
            try {
                if (!url) {
                    throw new Error("Empty url");
                }
                const token = this.token();
                const axios = _axios.create({
                    baseURL: IP,
                    headers: {
                        'famoz-horizon-web-frontend': 'SUPER_CALL',
                        "x-access-token": token
                    },
                    withCredentials: true
                });
                axios.post(url, body, option).then(data => {
                    if (data.data._COM.code === 4100) {
                        if (NET.callback) {
                            NET.callback();
                        }
                    }
                    resolve(data.data);
                })
                    .catch(e => {
                        reject(e);
                    });
            } catch (e) {
                reject(e);
            }
        });
    }


    delete = (url, option) => {
        return new Promise((resolve, reject) => {
            try {
                if (!url) {
                    throw new Error("Empty url");
                }
                const token = this.token();
                const axios = _axios.create({
                    baseURL: IP,
                    timeout: 10000,
                    headers: {
                        'famoz-horizon-web-frontend': 'SUPER_CALL',
                        "x-access-token": token
                    },
                    withCredentials: true
                });
                axios.delete(url, option).then(data => {
                    if (data.data._COM.code === 4100) {
                        if (NET.callback) {
                            NET.callback();
                        }
                    }
                    resolve(data.data);
                })
                    .catch(e => {
                        reject(e);
                    });
            } catch (e) {
                reject(e);
            }
        });
    }


    authCallback = (callback) => {
        NET.callback = callback;
    }
}