import React, { Component } from 'react'
import './styles/scrollNav.scss';
import { connect } from 'react-redux';
// import Tooltip from '@material-ui/core/Tooltip';

class ScrollNav extends Component {

  render() {
      const {location} = this.props;
      let black = false;
      if (location === "fourthPage") {
        black = true;
      }
      
    return (
        <nav className={black ? "menu_nav black" : "menu_nav"}>
          {/* <Tooltip title={"firstPage"} placement="left" ><a data-menuanchor="firstPage" href="#firstPage" className={"firstPage" === location ? "active" : ""}> </a></Tooltip>
          <Tooltip title={"secondPage"} placement="left"><a data-menuanchor="secondPage" href="#secondPage" className={"secondPage" === location ? "active" : ""}> </a></Tooltip>
          <Tooltip title={"thirdPage"} placement="left"><a data-menuanchor="thirdPage" href="#thirdPage" className={"thirdPage" === location ? "active" : ""}> </a></Tooltip> */}
          <a data-menuanchor="firstPage" href="#firstPage" className={"firstPage" === location ? "active" : ""}> </a>
          <a data-menuanchor="secondPage" href="#secondPage" className={"secondPage" === location ? "active" : ""}> </a>
          <a data-menuanchor="thirdPage" href="#thirdPage" className={"thirdPage" === location ? "active" : ""}> </a>
          <a data-menuanchor="fourthPage" href="#fourthPage" className={"fourthPage" === location ? "active" : ""}> </a>
          <a data-menuanchor="fifthPage" href="#fifthPage" className={"fifthPage" === location ? "active" : ""}> </a>
        </nav>
    )
  }
}

const props = (state) => {
  return {
    location: state.location.value
  }
}

export default connect (props, undefined) (ScrollNav);
