import React, { Component } from 'react'
import Reactfullpage from "@fullpage/react-fullpage";
import Sections from './sections';
import { connect } from 'react-redux';
import './styles/fullpageWr.scss';
import { location, selectPage } from '../redux/actions/index';
import ScrollNav from '../navigation/scrollNav';
import NET from "../common/function/net";
import FullpageEvent from './fullpageEvent';

class fullpageWr extends Component {

  state = {
    home: [],
    render: false,
    scroll: true
  }

  scrollEvent;
  

  constructor() {
    super();
    this.net = new NET();
    this.scrollEvent = FullpageEvent.root();

    // 스크롤 되면 안돼
    this.scrollEvent.setEventListener("openPopup", () => {
      this.setState({scroll: false});
    });

    // 스크롤 사용 가능
    this.scrollEvent.setEventListener("closePopup", () => {
      this.setState({scroll: true});
    });

  }

  anchors = [
    "firstPage", "secondPage", "thirdPage", "fourthPage", "fifthPage"
  ]

  componentDidMount = () => {
    this.props.onPageMount("home");
    this.getData();
  }

  componentWillUnmount = () => {
    this.setState({ render: true });
  }

  getData = () => {
    this.net.get("api/homepage/?menu=home").then(res => {
      this.setState({ home: res.data, render: true }, () => {
        if (window.location.href.match("#") !== null) {
          const page = String(window.location.href).slice(window.location.href.lastIndexOf("#") + 1)
          this.props.onScrollPage(page);
        } else {
          this.props.onScrollPage(this.anchors[0]);
        }
      });
    }).catch(err => {
    });
  }


  onLeave = (index, nextI, dir) => {
    if (dir === "up") {
      const page = nextI.anchor;
      this.props.onScrollPage(page);
    } else {
      const page = nextI.anchor;
      this.props.onScrollPage(page);
    }
  }
  
    render() {
      if(this.state.render) {
        return (
            <React.Fragment>
              <Reactfullpage
                licenseKey={"91806B22-3FDC4AEF-9AA48D1D-B669247E"}
                anchors={this.anchors}
                onLeave={this.onLeave}
                setKeyboardScrolling={true}
                resetSliders={true}
                dragAndMove={true}
                normalScrollElements={".slide_list_wr, textarea, .info_use_box"}
                render={({ state, fullpageApi }) => {

                  if(fullpageApi) {
                    fullpageApi.setAllowScrolling(this.state.scroll);
                    fullpageApi.setKeyboardScrolling(this.state.scroll);
                  }
                 
                  return (
                      <Sections state={state} home={this.state.home} fullpageApi={fullpageApi}/>
                  );
                }}
            />
            <ScrollNav /> 
          </React.Fragment>
          );
      }  else {
        return <div/>;
      }
    }
  }
    
const mapDispatchToProps = (dispatch) => {
  return {
    onScrollPage: (value) => dispatch(location(value)),
    onPageMount: (value) => dispatch(selectPage(value))
  }
}

export default connect(undefined, mapDispatchToProps)(fullpageWr)
