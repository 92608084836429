import React, { Component } from 'react'
import { Checkbox, Icon, Tooltip } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
// import i18n from '../../../i18n/i18n';
import 'react-dropdown/style.css';
import Dropdown from "react-dropdown";
import i18n from '../../../i18n/i18n';

class ContactPopup extends Component {
    state = {
        categories: [
            "솔루션 문의",
            "콘텐츠 문의",
            "디지털 스포츠 문의",
            "전시/홍보관 구축 문의",
            "컨설팅 문의",
            "기타 문의",
        ],
        // 목업 데이터

        company: "",
        name: "",
        phone: "",
        email: "",
        category: "",
        inquiry: "",
        useOk: false,
        // 팝업 안의 개인정보 활용 동의, 전화번호 이름 등의 실질적인 데이터

        invalid: false,
        // 각종 유효성 검사 시 필요
    }


    componentWillReceiveProps = (nextProps) => { 
        if (this.props.open !== nextProps.open) {
            if (this.props.contents) {
                this.setState({ category: this.state.categories[1], inquiry: `[참고하신 컨텐츠: ${nextProps.contents}] \n` })
            }
        }
        if ((this.props.contents !== nextProps.contents)) {
            this.setState({ category: this.state.categories[1], inquiry: `[참고하신 컨텐츠: ${nextProps.contents}] \n` })
        }
    }

    // 간단한 state 변경 시 사용할 수 있는 state 핸들러
    setStateHandler = (name, value) => {

        // if (name === "phone") {
        //     value = value.replace(/[^0-9]/g, '');
        // }

        this.setState({ [name]: value, invalid: false})
    }

    setStateClear = () => {
        this.setState({ email: "", phone: "", category: "", name: "", inquiry: "", company: "", useOk: false })
    }

    sendData = async () => {
        const { email, phone, category, name, inquiry, company } = this.state;

        // 빈 값 검사 && 이메일 유효성 검사
        if (phone === "" || category === "" || name === "" || inquiry.length <= 2) {
            this.setState({ invalid: true });
            return;
        }

        const data = {
            category: category ? category : "",   // 카테고리(옵션)
            company: company,  // 회사
            name: name,    // 이름
            phone: phone,   // 번호
            email: email ? email : "",      // 이메일(옵션)
            desc: inquiry      // 설명
        }
        
        await this.props.postMailContact(data);
        await this.props.setStateHandlerProps("open", false);
        setTimeout(() => {
            this.setStateClear();
        }, 1000)
        this.setState({ invalid: false })
    }

    renderWarning = () => {
        const {name, phone, category, invalid} = this.state;
        if (invalid && (phone === "" || category === "" || name === "")) {
            return (
                <p className="warning_text">! 내용이 정상적으로 입력되지 않았거나 필수 내용이 없습니다.</p>
            )
        }
    }

    setStateHandlerProps = (name, value) => {
        this.props.setStateHandlerProps(name, value);
        setTimeout(() => {
            this.setStateClear();
        }, 1000)
    }

    render() {
        const {open, data, wrapper } = this.props;
        const {setStateHandler, renderWarning, setStateHandlerProps} = this;
        const {company, name, phone, email, inquiry, useOk, categories, category} = this.state;

        return (
                    <Fade in={open}
                        mountOnEnter
                        timeout={{
                            appear: 500,
                            enter: 800,
                            exit: 500,
                        }} unmountOnExit>
                            <div className={wrapper ? "email_popup " + wrapper : "email_popup"}>
                            <div className="email_popup_black">
                            </div>
                            <div className="email_popup_inner">
                                <div className="X_btn out" onClick={(e) => setStateHandlerProps("open", false)}>
                                    <Icon>close</Icon>
                                </div>
                                <div className="email_popup_inner_scroll">
                                    <div className="scroll">
                                    <div className="X_btn inner" onClick={(e) => setStateHandlerProps("open", false)}>
                                        <Icon>close</Icon>
                                    </div>
                                        <div className="orange_area">
                                            <p className="connect_with_us">Connect with us.</p>
                                            {/* <p className="famoz_contact">Famoz Contact</p> */}
                                            <div className="popup_contents_input">
                                                <div className="half">
                                                    <label htmlFor="company">Company</label>
                                                    <input type="text" id="company" value={company} placeholder={"Company"} onChange={(e) => setStateHandler("company", e.target.value)} />
                                                </div> 
                                                <div className="half">
                                                    <label htmlFor="name">Name*</label>
                                                    <input type="text" id="name" value={name} placeholder={"Name*"} onChange={(e) => setStateHandler("name", e.target.value)} />
                                                </div>
                                                <div className="half">
                                                    <label htmlFor="phone">Phone*</label>
                                                    <input type="text" id="phone" value={phone} placeholder={"Phone*"} onChange={(e) => setStateHandler("phone", e.target.value)} />
                                                </div>
                                                <div className="half">
                                                    <label htmlFor="email">E-mail</label>
                                                    <input type="text" id="email" value={email} placeholder={"E-mail"} onChange={(e) => setStateHandler("email", e.target.value)} />
                                                </div>
                                                <div className="half">
                                                    <label htmlFor="category">Category</label>
                                                    <Dropdown id="category" className="selectBox" options={categories} onChange={(e) => setStateHandler("category", e.value)} value={category} placeholder="Category*" />
                                                </div>

                                                
                                            </div>
                                            <div className="popup_contents_inquiry">
                                                <textarea placeholder={i18n.t("home.inquiry")/*"문의 내용을 입력해주세요"*/} value={inquiry} onChange={(e) => setStateHandler("inquiry", e.target.value)} />
                                            </div> 
                                        </div>
                                        <div className="white_area">
                                            <div className="info_use">
                                                <Checkbox id="check_use_ok" style={{color: "#ff6714"}} value={useOk} onChange={(e) => setStateHandler("useOk", e.target.checked)} checked={useOk} />
                                                <span><label id="check_use_ok">개인정보보호정책에 동의합니다.</label></span>
                                            </div>
                                            
                                            <div className="info_use_box">
                                                <div className="scroll">
                                                    {/* { data.terms.ko } */}
                                                    <p dangerouslySetInnerHTML={{__html:  data.terms.ko }}/>
                                                </div>
                                            </div>
                                            {renderWarning()}
                                            {
                                                !useOk ? 
                                                <Tooltip placement={"top"} title="먼저 개인정보보호정책에 동의해주세요.">
                                                    <div className={useOk ? "send" : "send disabled"}>
                                                        Send
                                                    </div>
                                                </Tooltip>
                                                :
                                                
                                                <div className={useOk ? "send" : "send disabled"} onClick={(e) => this.sendData()}>
                                                    Send
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
            
        )

    }
}

export default ContactPopup;