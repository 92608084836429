import React, { Component } from 'react'
import content from '../../_assets/back_image/content.png';
import chart from '../../_assets/back_image/chart.png';
import system from '../../_assets/back_image/system.png'
import './styles/solutionFunction.scss';

export default class SolutionFunction extends Component {
  render() {

    return (
      <React.Fragment>
        <div className="solutionFunction_">
          <div className="bold_text_feature">
            <span className="span_wrap">
              MAIN FUNCTION   
            </span>
          </div>
            <div className="imgad">

              <div className="inner_content">
                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ content } alt={"콘텐츠 배포"} />
                    </div>
                    <div className="inner_title">
                      콘텐츠배포
                    </div>
                    <div className="inner_sub_title">
                      규모의 재약없이<br />
                      다양한 콘텐츠를 동시지속적으로<br />
                      배포하고 업데이트합니다.<br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ chart } alt={"템플릿 시스템"} />
                    </div>
                    <div className="inner_title">
                      리소스 관리
                    </div>
                    <div className="inner_sub_title">
                      영상/이미지/텍스트 등 다양한<br />
                      형태의 리소스의 구조를<br />
                      정의하고 손쉽게 관리합니다.<br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ system } alt={"원격제어"} />
                    </div>
                    <div className="inner_title">
                      원격제어
                    </div>
                    <div className="inner_sub_title">
                      웹을 통해서 별도의<br />
                      프로그램 설치나 설정없이<br />
                      장애가 발생한 디바이스를<br />
                      원격으로 제어할 수 있습니다.<br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ content } alt={"실시간 데이터 변동"} />
                    </div>
                    <div className="inner_title">
                      실시간 데이터 변동
                    </div>
                    <div className="inner_sub_title">
                      변경된 데이터를 즉시 전달하여 콘텐츠를<br />
                      항상 최신의 상태로 유지합니다. <br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ chart } alt={"실시간 데이터 변동"} />
                    </div>
                    <div className="inner_title">
                      실시간 데이터 변동
                    </div>
                    <div className="inner_sub_title">
                      변경된 데이터를 즉시 전달하여 콘텐츠를<br />
                      항상 최신의 상태로 유지합니다. <br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ system } alt={"실시간 데이터 변동"} />
                    </div>
                    <div className="inner_title">
                      실시간 데이터 변동
                    </div>
                    <div className="inner_sub_title">
                      변경된 데이터를 즉시 전달하여 콘텐츠를<br />
                      항상 최신의 상태로 유지합니다. <br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ content } alt={"실시간 데이터 변동"} />
                    </div>
                    <div className="inner_title">
                      실시간 데이터 변동
                    </div>
                    <div className="inner_sub_title">
                      변경된 데이터를 즉시 전달하여 콘텐츠를<br />
                      항상 최신의 상태로 유지합니다. <br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ chart } alt={"실시간 데이터 변동"} />
                    </div>
                    <div className="inner_title">
                      실시간 데이터 변동
                    </div>
                    <div className="inner_sub_title">
                      변경된 데이터를 즉시 전달하여 콘텐츠를<br />
                      항상 최신의 상태로 유지합니다. <br />
                    </div>
                  </div>
                </div>

                <div className="content_box">
                  <div className="content_box_inner">
                    <div className="imgbox">
                        <img src={ system } alt={"실시간 데이터 변동"} />
                    </div>
                    <div className="inner_title">
                      실시간 데이터 변동
                    </div>
                    <div className="inner_sub_title">
                      변경된 데이터를 즉시 전달하여 콘텐츠를<br />
                      항상 최신의 상태로 유지합니다. <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    )
  }
}
