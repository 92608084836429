import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectPage } from '../../redux/actions';
import PageWr from '../../common/function/pageWr';
import image from '../../_assets/back_image/back_5.jpg';
import './styles/solution.scss';

 class Solution extends Component {

  componentDidMount () {
    this.props.onPageMount("solution");
  }
  
  render() {
    return (
      <PageWr image={image}>
        <div className="solution_">
          <div className="inner_text">
          <p className="md_text bold_text" style={{textAlign:'center'}}>
              FAMOZ SOLUTION
          </p>
          <p className="md_text">
              <span className="middle_text">The World Best Solution<span className="weight600"> To Lead World Market</span></span>
          </p>
        </div>
     </div>
      </PageWr>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch (selectPage(value))
  }
}

export default connect(undefined, mapDispatchToProps)(Solution)